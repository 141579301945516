/**
 * Loading Page
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './stylesLoading';

function Loading(props) {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <CircularProgress size={80} />
    </div>
  );
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
