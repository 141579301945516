/**
 * Displays sponsors' logos
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { styles } from './stylesSponsorLogos';

function SponsorLogos(props) {
  const { classes, logoUrls } = props;

  return (
    <>
      <Typography variant="subtitle2">Brought to you by:</Typography>
      <div className={classes.logos}>
        {logoUrls.map((url, i) => {
          return <img key={i} src={url} className={classes.image} alt="Logo" />;
        })}
      </div>
    </>
  );
}

SponsorLogos.propTypes = {
  classes: PropTypes.object.isRequired,
  logoUrls: PropTypes.array.isRequired,
};

export default withStyles(styles)(SponsorLogos);
