export const styles = () => ({
  icons: {
    '& > *': {
      margin: '5px 60px 0px 0px',
    },
  },
  icon: {
    fontSize: '36px',
  },
});
