/**
 * Countdown timer
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MediaQuery from 'react-responsive';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { styles } from './stylesCountdown';

function daysCountdown(s) {
  let countdown = '';
  const days = Math.floor(s / 86400);
  if (days < 10) {
    countdown += '0';
  }
  countdown += days;
  return countdown;
}

function hoursCountdown(s) {
  let countdown = '';
  const hours = Math.floor(s / 3600) % 24;
  if (hours < 10) {
    countdown += '0';
  }
  countdown += hours;
  return countdown;
}

function minutesCountdown(s) {
  let countdown = '';
  const minutes = Math.floor(s / 60) % 60;
  if (minutes < 10) {
    countdown += '0';
  }
  countdown += minutes;
  return countdown;
}

function secondsCountdown(s) {
  let countdown = '';
  const seconds = s % 60;
  if (seconds < 10) {
    countdown += '0';
  }
  countdown += seconds;
  return countdown;
}

function Countdown(props) {
  const { classes, startTime, setStatus } = props;
  const secondsUntil = Math.floor((startTime - Date.now()) / 1000);
  const [timer, setTimer] = useState(secondsUntil);
  const id = useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  /**
   * Reloads the page when the countdown reaches 0 to redirect to the live event
   */
  useEffect(() => {
    if (timer === 0) {
      setStatus('CURRENT');
      window.location.reload(true);
      clear();
    }
  }, [timer]);

  const largeCountdown = (
    <>
      <Typography variant="subtitle2">Event Countdown</Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.countGrid}
      >
        <Grid item>
          <div className={classes.countBox}>{daysCountdown(timer)}</div>
          <Typography variant="body1">Days</Typography>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBox}>{hoursCountdown(timer)}</div>
          <Typography variant="body1">Hours</Typography>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBox}>{minutesCountdown(timer)}</div>
          <Typography variant="body1">Minutes</Typography>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBox}>{secondsCountdown(timer)}</div>
          <Typography variant="body1">Seconds</Typography>
        </Grid>
      </Grid>
    </>
  );

  const smallCountdown = (
    <>
      <Typography variant="subtitle2">Event Countdown</Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.countGridSmall}
      >
        <Grid item>
          <div className={classes.countBoxSmall}>{daysCountdown(timer)}</div>
          <Typography variant="body1">Days</Typography>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBoxSmall}>{hoursCountdown(timer)}</div>
          <Typography variant="body1">Hrs</Typography>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBoxSmall}>{minutesCountdown(timer)}</div>
          <Typography variant="body1">Mins</Typography>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBoxSmall}>{secondsCountdown(timer)}</div>
          <Typography variant="body1">Secs</Typography>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <MediaQuery query="(max-width: 650px)">{smallCountdown}</MediaQuery>
      <MediaQuery query="(min-width: 651px) and (max-width: 991px)">
        {largeCountdown}
      </MediaQuery>
      <MediaQuery query="(min-width: 992px) and (max-width: 1350px)">
        {smallCountdown}
      </MediaQuery>
      <MediaQuery query="(min-width: 1351px)">{largeCountdown}</MediaQuery>
    </>
  );
}

Countdown.propTypes = {
  classes: PropTypes.object.isRequired,
  startTime: PropTypes.number.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default withStyles(styles)(Countdown);
