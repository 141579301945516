/* global analytics */
/**
 * Landing Page
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MediaQuery from 'react-responsive';
import { defaultBannerUrl, delay } from '../../utils';
import Loading from '../Loading';
import EventDescription from '../../components/EventDescription';
import SocialFooter from '../../components/SocialFooter';
import SponsorLogos from '../../components/SponsorLogos';
import InfoCard from '../../components/InfoCard';
import { styles } from './stylesLandingPage';

function LandingPage(props) {
  const { classes, eventInfo, imagesLoading, setImagesLoading } = props;

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    delay(2000).then(() => {
      if (isMounted) setImagesLoading(false);
    });
    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  });

  useEffect(() => {
    // Update the document title using the browser API
    document.title = 'Gatherly | Event Landing Page';
    // Load Intercom via Segment
    analytics.page();
  });

  const startTime = eventInfo.startTime;
  const stopTime = eventInfo.stopTime;
  const capacity = eventInfo.capacity;
  const name = eventInfo.name;
  const description = eventInfo.config.landingPage.description;
  const logoUrls = eventInfo.config.landingPage.logoUrls;
  const bannerUrl =
    eventInfo.config.landingPage.bannerUrl === ''
      ? defaultBannerUrl
      : eventInfo.config.landingPage.bannerUrl;
  const userId = eventInfo.userId;

  const socialFooterDiv = !eventInfo.config.landingPage.socialDisabled ? (
    <div className={classes.social}>
      <SocialFooter />
    </div>
  ) : (
    <></>
  );

  return (
    <>
      <img className={classes.banner} src={bannerUrl} alt="banner" />
      <div className={classes.container}>
        <MediaQuery query="(min-width: 992px)">
          <Grid container spacing={0}>
            <Grid item xs={7}>
              <div className={classes.lowGrid}>
                <EventDescription
                  startTime={startTime}
                  stopTime={stopTime}
                  capacity={capacity}
                  description={description}
                  name={name}
                  userId={userId}
                />
                {socialFooterDiv}
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className={classes.highGrid}>
                <div className={classes.center}>
                  <InfoCard startTime={startTime} stopTime={stopTime} />
                </div>
                <div className={classes.logos}>
                  {logoUrls.length > 0 && <SponsorLogos logoUrls={logoUrls} />}
                </div>
              </div>
            </Grid>
          </Grid>
        </MediaQuery>
        <MediaQuery query="(max-width: 991px)">
          <div className={classes.highGrid}>
            <div className={classes.center}>
              <InfoCard startTime={startTime} stopTime={stopTime} />
            </div>
            <EventDescription
              startTime={startTime}
              stopTime={stopTime}
              capacity={capacity}
              description={description}
              name={name}
              userId={userId}
            />
            <div className={classes.logos}>
              {logoUrls.length > 0 && <SponsorLogos logoUrls={logoUrls} />}
            </div>
            {socialFooterDiv}
          </div>
        </MediaQuery>
      </div>
      {imagesLoading && <Loading />}
    </>
  );
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  eventInfo: PropTypes.object,
  imagesLoading: PropTypes.bool.isRequired,
  setImagesLoading: PropTypes.func.isRequired,
};

export default withStyles(styles)(LandingPage);
