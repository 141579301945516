export const styles = () => ({
  countGrid: {
    fontSize: '40px',
    fontWeight: 'bold',
    padding: '20px 30px 0px 30px',
    width: '500px',
  },
  countBox: {
    borderRadius: '7px',
    backgroundColor: '#FFFFFF',
    height: '80px',
    minWidth: '80px',
    textAlign: 'center',
    lineHeight: '80px',
  },
  separator: {
    paddingBottom: '30px',
  },
  countGridSmall: {
    fontSize: '25px',
    fontWeight: 'bold',
    padding: '20px 10px 0px 10px',
    width: '300px',
  },
  countBoxSmall: {
    borderRadius: '7px',
    backgroundColor: '#FFFFFF',
    height: '50px',
    width: '50px',
    textAlign: 'center',
    lineHeight: '50px',
  },
});
