export const styles = () => ({
  container: {
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    padding: '10px',
  },
  content: {
    width: '100%',
    maxWidth: '1000px',
    textAlign: 'center',
  },
  sleeb: {
    maxWidth: '500px',
    width: '100%',
  },
});
