export const defaultBannerUrl =
  // eslint-disable-next-line max-len
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-banners/Gradient+Banner.png';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getDateTimeFromUnixTimestamp = (unixTimeStamp) => {
  const date = new Date(unixTimeStamp);
  let hour = date.getHours();
  const minute = ('0' + date.getMinutes()).slice(-2);
  const suffix = date.getHours() >= 12 ? 'pm' : 'am';
  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour -= 12;
  }
  const month = date.getMonth() + 1;
  const day = ('0' + date.getDate()).slice(-2);
  return hour + ':' + minute + suffix + ' ' + month + '/' + day;
};

export const getTimeZone = () => {
  const zone = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];
  return zone;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
