/**
 * Social media component for sharing event
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { styles } from './stylesSocialFooter';

function SocialFooter(props) {
  const { classes } = props;
  const url = window.location.href;

  return (
    <>
      <Typography variant="subtitle2">
        Share this Event on Social Media:
      </Typography>
      <div className={classes.icons}>
        <FacebookShareButton url={url}>
          <FacebookIcon className={classes.icon} />
        </FacebookShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon className={classes.icon} />
        </TwitterShareButton>
        <LinkedinShareButton url={url}>
          <LinkedInIcon className={classes.icon} />
        </LinkedinShareButton>
      </div>
    </>
  );
}

SocialFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialFooter);
