/**
 * Displays date event ended
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { monthNames } from '../../utils';

const styles = () => ({
  container: {
    padding: '50px',
  },
});

function dateSuffix(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

function EventOver(props) {
  const { stopTime, classes } = props;
  const date = new Date(stopTime);
  const day = date.getDate();

  return (
    <div className={classes.container}>
      <Typography variant="body1">
        {'This event ended on ' +
          monthNames[date.getMonth()] +
          ' ' +
          day +
          dateSuffix(day)}
      </Typography>
    </div>
  );
}

EventOver.propTypes = {
  classes: PropTypes.object.isRequired,
  stopTime: PropTypes.number.isRequired,
};

export default withStyles(styles)(EventOver);
