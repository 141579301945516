/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "aliasApi",
            "endpoint": "https://8qgr5q33be.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "eventsApi",
            "endpoint": "https://o5xi23o5hk.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "userApi",
            "endpoint": "https://li3tayadue.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
