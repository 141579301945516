/**
 * Calendar icon
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { monthNames } from '../../utils';

const styles = () => ({
  calendar: {
    display: 'inline-block',
    width: '100px',
    height: '120px',
  },
  header: {
    backgroundColor: '#E85650',
    textAlign: 'center',
    color: '#FFFFFF',
    height: '40px',
  },
  body: {
    textAlign: 'center',
  },
});

function Calendar(props) {
  const { classes, startTime } = props;
  const date = new Date(startTime);
  const month = monthNames[date.getMonth()].substring(0, 3);
  const day = date.getDate();

  return (
    <Card className={classes.calendar}>
      <CardHeader
        title={month}
        titleTypographyProps={{ variant: 'h5' }}
        className={classes.header}
      />
      <CardContent className={classes.body}>
        <Typography variant="h4">{day}</Typography>
      </CardContent>
    </Card>
  );
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
  startTime: PropTypes.number.isRequired,
};

export default withStyles(styles)(Calendar);
