/**
 * Main logic for our Events Landing Page
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React, { useState, useEffect } from 'react';
import NoEvent from './pages/NoEvent';
import Loading from './pages/Loading';
import LandingPage from './pages/LandingPage';
import Amplify, { API } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

/**
 * Checks if a character is part of a valid URL string
 *
 * @param {character} s The character in question
 * @return {boolean} Indicating whether character is part of our URL
 */
function isUrlCharacter(s) {
  return s.match("^[A-Za-z0-9-_!~*'()]+$");
}

/**
 * Converts url to the corresponding alias for lookup in the
 * betterDmvAliases-production table
 *
 * @return {string} *.event.gatherly.io
 */
function getAlias() {
  const url = window.location.href;
  const end = url.indexOf('.event.gatherly');
  if (end === -1) {
    return '';
    // return 'my-company-social.event.gatherly.io';
  }
  let start = end - 1;
  while (isUrlCharacter(url.charAt(start)) && start >= 0) {
    start--;
  }
  return url.substring(start + 1, end) + '.event.gatherly.io';
}

/**
 * Queries aliasAPI to get the eventId for our alias
 *
 * @return {string} eventId if query is successful, empty string if not
 */
async function queryEventId() {
  const alias = getAlias();
  if (alias) {
    const aliasApiName = 'aliasApi';
    const aliasPathWithQuery = `/items`;
    const aliasMyInit = {
      queryStringParameters: {
        alias: alias,
      },
    };
    const aliasItems = await API.get(
      aliasApiName,
      aliasPathWithQuery,
      aliasMyInit,
    );
    const eventId = aliasItems[0]?.eventId;
    return eventId;
  }
  return '';
}

/**
 * Queries eventsAPI to retrieve the event information for our eventId
 *
 * @param {string} eventId  The eventId for lookup in our
 *                          betterDmvEvents-production table
 * @return {JSON}           Data for the selected event
 */
async function queryEventInfo(eventId) {
  const aliasApiName = 'eventsApi';
  const aliasPathWithQuery = `/events`;
  const aliasMyInit = {
    queryStringParameters: {
      eventId: eventId,
    },
  };
  const eventInfo = await API.get(
    aliasApiName,
    aliasPathWithQuery,
    aliasMyInit,
  );
  return eventInfo;
}

function App() {
  const [loading, setLoading] = useState(true);
  const [eventInfo, setEventInfo] = useState(null);
  // Used in LandingPage, gives us a few seconds of load
  // while images load from s3
  const [imagesLoading, setImagesLoading] = useState(true);

  useEffect(() => {
    async function checkDynamo() {
      const eventId = await queryEventId();
      if (eventId) {
        const eventInfo = await queryEventInfo(eventId);
        if (eventInfo.length > 0) {
          setEventInfo(eventInfo[0]);
        }
      }
      setLoading(false);
    }
    checkDynamo();
    // Updates every 5 minutes in case of change to start time
    const interval = setInterval(() => checkDynamo(), 300000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * Returns the appropriate page based on the results of our dynamo queries
   */
  function RetrievePage() {
    if (loading) {
      return <Loading />;
    } else if (!eventInfo || eventInfo.archived) {
      return <NoEvent />;
    } else {
      return (
        <LandingPage
          eventInfo={eventInfo}
          imagesLoading={imagesLoading}
          setImagesLoading={setImagesLoading}
        />
      );
    }
  }

  return <RetrievePage />;
}

export default App;
