import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './index.css';
import App from './App';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#002FAF',
    },
    secondary: {
      main: '#C04483',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1800,
    },
  },
  typography: {
    fontFamily: ['Lato, sans-serif'],
    h1: {
      fontWeight: 'bold',
      fontSize: '96px',
      lineHeight: '118px',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '60px',
      lineHeight: '80px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '48px',
      lineHeight: '64px',
    },
    h4: {
      fontWeight: 600,
      fontSize: '36px',
      lineHeight: '48px',
    },
    h5: {
      fontSize: '30px',
      lineHeight: '42px',
    },
    subtitle1: {
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '44px',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '36px',
    },
    body1: {
      fontSize: '18px',
      lineHeight: '28px',
    },
    body2: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    button: {
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '28px',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiButton: {
      root: {
        height: '40px',
      },
      label: {
        textTransform: 'none',
        fontWeight: 600,
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        height: '100%',
      },
      indicator: {
        height: 5,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        // backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
        fontSize: 16,
        fontWeight: 600,
      },
      labelIcon: {
        '& .MuiSvgIcon-root': {
          marginBottom: '0 !important',
        },
      },
      textColorSecondary: {
        '&.Mui-selected': {
          color: '#00A9F2',
        },
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#00A9F2',
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 18,
        fontWeight: 400,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiGrid: {
      root: {
        position: 'relative',
      },
    },
    MuiDialog: {
      root: {
        zIndex: 5000,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#00A9F2',
      },
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
