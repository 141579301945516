/**
 * Displays number of expected attendees
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const styles = () => ({
  icon: {
    float: 'left',
    paddingRight: '5px',
    fontSize: '28px',
  },
});

function Capacity(props) {
  const { classes, capacity } = props;

  return (
    <>
      <SupervisedUserCircleIcon className={classes.icon} />
      <Typography variant="body1">
        {capacity + ' expected attendees'}
      </Typography>
    </>
  );
}

Capacity.propTypes = {
  classes: PropTypes.object.isRequired,
  capacity: PropTypes.string.isRequired,
};

export default withStyles(styles)(Capacity);
