export const styles = () => ({
  logos: {
    '& > *': {
      margin: '2vh 10px',
    },
  },
  image: {
    height: '100px',
  },
});
