/* global analytics */
/**
 * Page displayed when trying to access a URL for an event that doesn't exist
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import sleeb from '../../assets/sleeb.gif';
import { styles } from './stylesNoEvent';

function NoEvent(props) {
  const { classes } = props;

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Gatherly | 404 Landing Page`;
    // Load Intercom via Segment
    analytics.page();
  });

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img className={classes.sleeb} src={sleeb} alt="404" />
        <br />
        <Typography variant="h4">
          Oops... looks like this event isn&apos;t active at the moment.
        </Typography>
        <br />
        <Typography variant="body1">
          If you&apos;re sure that the <strong>link</strong> and
          <strong> time</strong> are correct for the event you wish to join,
          this page may be cached. Click the button below to refresh this page,
          or try opening this link in&nbsp;
          <a
            href="https://support.google.com/chrome/answer/95464"
            target="_blank"
            rel="noreferrer"
          >
            Google Chrome&apos;s incognito mode
          </a>
          !
        </Typography>
        <br />
        <button
          onClick={() => window.location.reload(true)}
          type="button"
          className="btn btn-primary btn-lg"
        >
          Refresh
        </button>
      </div>
    </div>
  );
}

NoEvent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoEvent);
