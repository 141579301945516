/**
 * Displays event description
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  showMore: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

function Description(props) {
  const { classes, description } = props;

  const [showMore, setShowMore] = useState(false);
  const longDescription = description.length >= 400;

  return (
    <>
      <Typography variant="subtitle2">About this Event:</Typography>
      <Typography variant="body1">
        {showMore || !longDescription
          ? description
          : description.slice(0, 399) + ' ...'}
      </Typography>
      {longDescription && (
        <Typography
          variant="body1"
          className={classes.showMore}
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {showMore ? 'show less' : 'show more'}
        </Typography>
      )}
    </>
  );
}

Description.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
};

export default withStyles(styles)(Description);
