export const styles = (theme) => ({
  banner: {
    objectFit: 'cover',
    width: '100%',
    height: '25vh',
    [theme.breakpoints.up('lg')]: {
      height: '33vh',
    },
  },
  container: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
  },
  lowGrid: {
    margin: '36vh 5vw 0vh 5vw',
  },
  highGrid: {
    margin: '17vh 5vw 0vh 5vw',
    [theme.breakpoints.up('lg')]: {
      margin: '25vh 5vw 0vh 5vw',
    },
  },
  center: {
    textAlign: 'center',
    paddingBottom: '3vh',
  },
  logos: {
    paddingBottom: '3vh',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '200px',
    },
  },
  social: {
    paddingBottom: '3vh',
  },
});
