/**
 * Info Card displayed in the top of the landing page
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Countdown from './Countdown';
import LiveEvent from './LiveEvent';
import EventOver from './EventOver';

const styles = (theme) => ({
  card: {
    backgroundColor: '#EDEEF4',
    display: 'inline-block',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: '6vw',
    },
  },
});

function getStatus(startTime, stopTime) {
  if (stopTime < Date.now()) {
    return 'PAST';
  } else if (startTime > Date.now()) {
    return 'FUTURE';
  }
  return 'CURRENT';
}

function InfoCard(props) {
  const { classes, startTime, stopTime } = props;
  const [status, setStatus] = useState(getStatus(startTime, stopTime));

  return (
    <Card className={classes.card}>
      <CardContent>
        {status === 'FUTURE' && (
          <Countdown startTime={startTime} setStatus={setStatus} />
        )}
        {status === 'CURRENT' && <LiveEvent />}
        {status === 'PAST' && <EventOver stopTime={stopTime} />}
      </CardContent>
    </Card>
  );
}

InfoCard.propTypes = {
  classes: PropTypes.object.isRequired,
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number.isRequired,
};

export default withStyles(styles)(InfoCard);
