export const styles = () => ({
  container: {
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: '#FFFFFF',
    zIndex: '147483647',
    display: 'grid',
    placeItems: 'center',
  },
});
