/**
 * Displays button to enter event if live
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = () => ({
  btn: {
    padding: '50px 30px',
    '&:focus': {
      outline: 'none',
    },
  },
  spacing: {
    paddingTop: '20px',
  },
  arrow: {
    fontSize: '80px',
  },
});

function LiveEvent(props) {
  const { classes } = props;
  const url = window.location.href;

  return (
    <>
      <Typography variant="body1">{'This event is live!'}</Typography>
      <div className={classes.spacing}>
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          onClick={() => {
            window.open(url, '_blank');
          }}
        >
          <Typography variant="h4">
            Join Now
            <span className={classes.arrow}>&#x2192;</span>
          </Typography>
        </Button>
      </div>
    </>
  );
}

LiveEvent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LiveEvent);
