/**
 * Buttons in the event info section
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  spacing: {
    paddingRight: '10px',
  },
  emailBtn: {
    width: '140px',
    height: '44px',
    display: 'inline-block',
    position: 'relative',
    fontFamily: 'sans-serif',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    fontWeight: '540',
    fontSize: '15px',
    lineHeight: '100%',
    border: '1px solid #cfcfcf',
    webkitBoxShadow: '1px 1px 3px rgba(0,0,0,0.15)',
    mozBoxShadow: '1px 1px 3px rgba(0,0,0,0.15)',
    boxShadow: '1px 1px 3px rgba(0,0,0,0.15)',
    webkitBorderRadius: '2px',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
});

/**
 * Formats the start and end time properly for the "Add to Calendar"
 * button
 *
 * @return {string} date formatted in mm/dd/yyyy hh:mm AM/PM
 */
function formatDate(unixTimeStamp) {
  const date = new Date(unixTimeStamp);
  let hour = date.getHours();
  const minute = ('0' + date.getMinutes()).slice(-2);
  const suffix = date.getHours() >= 12 ? 'PM' : 'AM';
  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour -= 12;
  }
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const year = date.getFullYear();
  return (
    month + '/' + day + '/' + year + ' ' + hour + ':' + minute + ' ' + suffix
  );
}

function Buttons(props) {
  const { classes, name, startTime, stopTime, description, userId } = props;
  const [email, setEmail] = useState('');
  const url = window.location.href;
  // eslint-disable-next-line new-cap
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    async function checkDynamo() {
      const userApiName = 'userApi';
      const userPathWithQuery = `/users`;
      const userMyInit = {
        queryStringParameters: {
          userId: userId,
        },
      };
      const userItems = await API.get(
        userApiName,
        userPathWithQuery,
        userMyInit,
      );
      setEmail(userItems[0].email);
    }
    checkDynamo();
  }, []);

  return (
    <table>
      <tbody>
        <tr>
          <td className={classes.spacing}>
            <div title="Add to Calendar" className="addeventatc">
              Add to Calendar
              <span className="start">{formatDate(startTime)}</span>
              <span className="end">{formatDate(stopTime)}</span>
              <span className="timezone">{timeZone}</span>
              <span className="title">{name}</span>
              <span className="description">{description}</span>
              <span className="location">{url}</span>
            </div>
          </td>
          <td>
            <button
              className={classes.emailBtn}
              onClick={() => {
                location.href = 'mailto:' + email;
              }}
            >
              Email the Host
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

Buttons.propTypes = {
  classes: PropTypes.object.isRequired,
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default withStyles(styles)(Buttons);
