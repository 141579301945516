/**
 * Event info title area
 *
 * @author [Mark Foster](https://github.com/markfoster314)
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Calendar from './Calendar';
import { getDateTimeFromUnixTimestamp, getTimeZone } from '../../utils';
import { Typography } from '@material-ui/core';

const styles = () => ({
  calendar: {
    paddingRight: '10px',
  },
  time: {
    color: '#E85650',
  },
  showMore: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

function Title(props) {
  const { classes, name, startTime, stopTime } = props;

  const [showMore, setShowMore] = useState(false);
  const longName = name.length >= 80;

  return (
    <table>
      <tbody>
        <tr>
          <td className={classes.calendar}>
            <Calendar startTime={startTime} />
          </td>
          <td>
            <Typography variant="subtitle2" className={classes.time}>
              {getDateTimeFromUnixTimestamp(startTime) +
                ' - ' +
                getDateTimeFromUnixTimestamp(stopTime) +
                ' ' +
                getTimeZone()}
            </Typography>
            <Typography variant="h4">
              {showMore || !longName ? name : name.slice(0, 79) + ' ...'}
            </Typography>
            {longName && (
              <Typography
                variant="body1"
                className={classes.showMore}
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                {showMore ? 'show less' : 'show more'}
              </Typography>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default withStyles(styles)(Title);
